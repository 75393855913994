import React from "react";
import { StackProps, Typography, TypographyProps } from "@mui/material";
import { dynamicallyLoadIcon } from "../../icons";
import { useStore } from "../../store";
import { RichText } from "../../textFields";
import { SECTION_NAME_RENDER_SVG } from "../../utils";
import { Container, IconWrapper } from "./styles";

type PpSectionHeading = {
  alignItems?: StackProps["alignItems"];
  description?: string;
  sectionName?: string;
  standardWidth?: boolean;
  textAlign?: TypographyProps["align"];
  textColor?: TypographyProps["color"];
  title?: string;
};

export function SectionHeading({
  alignItems = "center",
  description,
  sectionName = "",
  standardWidth = true,
  textAlign = "inherit",
  textColor = "primary",
  title,
  ...rest
}: PpSectionHeading): React.ReactElement {
  const { siteInfo } = useStore();

  function renderSectionNameField(): React.ReactElement | null {
    if (sectionName === SECTION_NAME_RENDER_SVG)
      return (
        <IconWrapper>{dynamicallyLoadIcon(siteInfo?.sectionIcon)}</IconWrapper>
      );
    if (sectionName !== "")
      return (
        <Typography
          gutterBottom
          align={textAlign}
          color="primary.light"
          component="p"
          variant="section--name"
        >
          {sectionName}
        </Typography>
      );
    return null;
  }

  return (
    <Container $standardWidth={standardWidth} alignItems={alignItems} {...rest}>
      {renderSectionNameField()}
      {title && (
        <RichText
          gutterBottom
          align={textAlign}
          color={textColor}
          component="h2"
          text={title}
          variant="h2"
        />
      )}
      {description && (
        <RichText
          gutterBottom
          align={textAlign}
          color={textColor}
          text={description}
          variant="body1"
        />
      )}
    </Container>
  );
}
