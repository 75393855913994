const portalID = "6835722";
const formID = "f9f28cc4-1653-4806-a95c-9a0579c712e9";

export const apiEndpoint = `https://api.hsforms.com/submissions/v3/integration/submit/${portalID}/${formID}`;

export const servicesOptions = [
  {
    label: "Italian Citizenship",
    value: "Citizenship",
  },
  {
    label: "Family Tree Research",
    value: "Research",
  },
  {
    label: "Heritage Travel",
    value: "Travel",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const initialFormValues = {
  email: "",
  firstname: "",
  lastname: "",
  message: "",
  service_type: "",
};
