import React from "react";
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  Typography,
} from "@mui/material";
import { CheckboxContainer } from "./styles";

type PpCheckbox = MuiCheckboxProps & {
  checked: boolean;
  darkMode?: boolean;
  id: string;
  label: string | JSX.Element;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export function Checkbox({
  checked,
  darkMode = false,
  id,
  label,
  name,
  onChange,
  ...rest
}: PpCheckbox): React.ReactElement {
  return (
    <CheckboxContainer $darkMode={darkMode}>
      <MuiCheckbox
        disableRipple
        checked={checked}
        id={id}
        inputProps={{ "aria-label": name }}
        name={name}
        onChange={onChange}
        {...rest}
      />
      {typeof label === "string" ? (
        <Typography variant="body2">{label}</Typography>
      ) : (
        label
      )}
    </CheckboxContainer>
  );
}
