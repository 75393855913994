import React from "react";
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";

type PpInput = MuiTextFieldProps & {
  id: string;
  label?: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  pattern?: string;
  placeholder: string;
  title?: string;
  value: string;
};

export function FormInput({
  id,
  label,
  name,
  onChange,
  pattern,
  placeholder,
  title,
  value,
  ...rest
}: PpInput): React.ReactElement {
  return (
    <MuiTextField
      id={id}
      InputLabelProps={{
        disabled: value === "",
        shrink: true,
      }}
      InputProps={{ disableUnderline: true }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{ pattern, title }}
      label={label}
      name={name}
      placeholder={placeholder}
      value={value}
      variant="standard"
      onChange={onChange}
      {...rest}
    />
  );
}
