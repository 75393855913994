import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { Button } from "../buttons";
import { SectionContainer, SectionHeading } from "../containers";
import { shouldForwardProp } from "../utils";

type PpContainer = {
  $darkMode: boolean;
};

export const Container = styled("div", { shouldForwardProp })<PpContainer>`
  ${({ $darkMode, theme }): SerializedStyles => css`
    width: 100%;
    height: 100%;
    ${$darkMode
      ? css`
          ${theme.cssMixins.heroLinearGradient};
        `
      : css`
          background-color: ${theme.palette.site.contactForm};
        `}
  `}
`;

type PpVerticalLayout = {
  $verticalLayout: boolean;
};

export const StyledContainer = styled(SectionContainer, {
  shouldForwardProp,
})<PpVerticalLayout>`
  ${({ $verticalLayout, theme }): SerializedStyles => css`
    ${$verticalLayout &&
    css`
      max-width: ${theme.breakpoints.values.md}px !important;
      margin-left: auto;
      margin-right: auto;
    `}
    .MuiTextField-root,
    .MuiFormControl-root {
      width: 100%;
    }
  `}
`;

export const LoadingSpinner = styled(Grid)`
  ${({ theme }): SerializedStyles => css`
    min-height: 600px;
    ${theme.breakpoints.up("sm")} {
      min-height: 655px;
    }
    ${theme.breakpoints.up("md")} {
      min-height: 500px;
    }
  `}
`;

export const Heading = styled(SectionHeading, {
  shouldForwardProp,
})<PpVerticalLayout>`
  ${({ $verticalLayout, theme }): SerializedStyles => css`
    ${theme.cssMixins.respTextAlign};
    ${$verticalLayout
      ? css`
          margin: 0 auto;
        `
      : css`
          ${theme.breakpoints.up("md")} {
            max-width: 90%;
          }
        `}
  `}
`;

export const SubmitButton = styled(Button)`
  width: fit-content;
`;
