import React from "react";
import { Helmet } from "react-helmet";

type PpSEO = {
  defaultTitle?: string;
  description?: string;
  image?: string;
  title?: string;
  titleTemplate?: string;
  url?: string;
};

export function SEO({
  defaultTitle,
  description,
  image,
  title,
  titleTemplate = "",
  url,
}: PpSEO): React.ReactElement {
  return (
    <Helmet
      defaultTitle={defaultTitle}
      htmlAttributes={{ lang: "en" }}
      title={title}
      titleTemplate={titleTemplate}
    >
      {title ? (
        <meta content={title} property="og:title" />
      ) : (
        <meta content={defaultTitle} property="og:title" />
      )}
      {title ? (
        <meta content={title} name="twitter:title" />
      ) : (
        <meta content={defaultTitle} name="twitter:title" />
      )}
      <meta content={description} name="description" />
      {description && <meta content={description} property="og:description" />}
      {description && <meta content={description} name="twitter:description" />}
      {url && <meta content={url} property="og:url" />}
      {image && <meta content={image} property="og:image" />}
      {image && <meta content={image} name="twitter:image" />}
    </Helmet>
  );
}
