import React from "react";
import { SEO as SEOComponent } from "@italymondo/core-ui";
import { useDefaultSiteMetadata } from "./query";

type PpSEO = {
  description?: string;
  image?: string;
  location?: {
    pathname?: string;
  };
  title?: string;
};

export function SEO({
  description,
  image,
  location,
  title,
}: PpSEO): React.ReactElement {
  const data = useDefaultSiteMetadata();
  const rootUrl = data?.siteMetadata?.siteUrl || "";
  const pathname = location?.pathname || "";

  const seo = {
    defaultTitle: data?.siteMetadata?.defaultTitle || "",
    description: description || data?.siteMetadata?.defaultDescription || "",
    title: title || "",
    titleTemplate: data?.siteMetadata?.titleTemplate || "",
    url: `${rootUrl}${pathname}`,
  };

  return <SEOComponent image={image} {...seo} />;
}
