import { graphql, useStaticQuery } from "gatsby";
import { SEOQuery } from "./query.generated";

export type DefaultSEOData = SEOQuery["site"];

export function useDefaultSiteMetadata(): DefaultSEOData {
  const data = useStaticQuery<SEOQuery>(
    graphql`
      query SEO {
        site {
          siteMetadata {
            defaultTitle: title
            titleTemplate
            defaultDescription: description
            siteUrl: url
          }
        }
      }
    `
  );
  return data.site;
}
