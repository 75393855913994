import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { shouldForwardProp } from "../../utils";

type PpContainer = {
  $darkMode: boolean;
};

export const CheckboxContainer = styled("div", {
  shouldForwardProp,
})<PpContainer>`
  ${({ $darkMode, theme }): SerializedStyles => css`
    ${theme.cssMixins.rowVCentered};
    .MuiCheckbox-root {
      padding-left: 0;
      color: ${$darkMode
        ? theme.palette.site.checkbox.darkMode
        : theme.palette.site.checkbox.lightMode} !important;
    }
  `};
`;
