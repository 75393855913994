/* eslint-disable sort-keys-fix/sort-keys-fix */
import React, { useRef, useState } from "react";
import { CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { navigate } from "gatsby";
import Cookies from "js-cookie";
import { useHasBeenAlmostVisible } from "../hooks";
import { Checkbox, FormInput } from "../inputs";
import { Link } from "../links";
import { Select } from "../selects";
import { apiEndpoint, initialFormValues, servicesOptions } from "./config";
import {
  Container,
  Heading,
  LoadingSpinner,
  StyledContainer,
  SubmitButton,
} from "./styles";

export type TpFormFields = {
  email: string;
  firstname: string;
  lastname: string;
  message: string;
  service_type: string;
};

type PpContactForm = {
  confirmationPageUrl: string;
  darkMode?: boolean;
  description: string;
  lazyLoad?: boolean;
  sectionName?: string;
  title: string;
  verticalLayout?: boolean;
};

export function ContactForm({
  confirmationPageUrl = "/form-submitted/",
  darkMode = false,
  description,
  lazyLoad = true,
  sectionName,
  title,
  verticalLayout = false,
  ...rest
}: PpContactForm): React.ReactElement {
  const [fields, setFields] = useState<TpFormFields>(initialFormValues);
  const [privacyAccepted, setPrivacyAccepted] = useState<boolean>(false);
  const elementRef = useRef<HTMLDivElement>(null);
  const shouldRenderContent = useHasBeenAlmostVisible(elementRef);

  function handleFieldChange(
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<{ name: string; value: string }>
  ): void {
    setFields({ ...fields, [e.target.name]: e.target.value });
  }

  async function handleSubmit(e: React.FormEvent): Promise<void> {
    e.preventDefault();
    if (privacyAccepted) {
      const formData = Object.keys(fields).map((fieldName: string) => ({
        name: fieldName,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        value: fields[fieldName],
      }));
      try {
        await fetch(apiEndpoint, {
          body: JSON.stringify({
            context: {
              ...(Cookies.get("hubspotutk")
                ? { hutk: Cookies.get("hubspotutk") }
                : {}),
              pageUri: window.location.href,
            },
            fields: formData,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
        });
        await navigate(confirmationPageUrl);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("error: ", error);
      }
    }
  }

  // temporarely HARDCODED link to legal index page
  const privacyText = (
    <Typography
      color={darkMode ? "primary.contrastText" : "primary"}
      variant="body2"
    >
      I accept the{" "}
      <Link color="primary.light" to="/legal">
        privacy policy.
      </Link>
    </Typography>
  );

  return (
    <Container ref={elementRef} $darkMode={darkMode} {...rest}>
      <StyledContainer $verticalLayout={verticalLayout}>
        <Grid
          container
          alignItems="center"
          component="form"
          direction={{ xs: "column", md: verticalLayout ? "column" : "row" }}
          spacing={3}
          onSubmit={handleSubmit}
        >
          <Grid item sm={6}>
            <Heading
              $verticalLayout={verticalLayout}
              alignItems={{
                xs: "center",
                md: verticalLayout ? "center" : "flex-start",
              }}
              description={description}
              sectionName={sectionName}
              standardWidth={verticalLayout}
              textAlign={verticalLayout ? "center" : "left"}
              textColor={darkMode ? "primary.contrastText" : "primary"}
              title={title}
            />
          </Grid>
          {shouldRenderContent || !lazyLoad ? (
            <React.Fragment>
              <Grid item sm={6}>
                <Grid
                  container
                  justifyContent={{ md: "flex-start", xs: "center" }}
                  spacing={{ xs: 2, sm: 4 }}
                >
                  <Grid container item sm={12} spacing={{ xs: 2, md: 4 }}>
                    <Grid item md={6} xs={12}>
                      <FormInput
                        required
                        id="firstname"
                        label="First Name"
                        name="firstname"
                        pattern="^(?=.{1,30}$)[a-zA-Z]+(?:[\-' ]?[a-zA-Z]+[\-' ]?)*$"
                        placeholder="First Name"
                        title="First name can only contain letters, spaces, apostrophes or hyphens and must start with a letter."
                        value={fields.firstname}
                        onChange={handleFieldChange}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormInput
                        required
                        id="lastname"
                        label="Last Name"
                        name="lastname"
                        pattern="^(?=.{1,30}$)[a-zA-Z]+(?:[.\-' ]?[a-zA-Z]+[.\-' ]?)*$"
                        placeholder="Last Name"
                        title="Last name can only contain letters, spaces, apostrophes, periods or hyphens and must start with a letter."
                        value={fields.lastname}
                        onChange={handleFieldChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item sm={12} spacing={{ xs: 2, md: 4 }}>
                    <Grid item md={6} xs={12}>
                      <FormInput
                        required
                        id="email"
                        label="Email"
                        name="email"
                        placeholder="Email Address"
                        type="email"
                        value={fields.email}
                        onChange={handleFieldChange}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Select
                        required
                        darkMode={darkMode}
                        id="service_type"
                        name="service_type"
                        placeholder="How Can We Help?"
                        values={servicesOptions}
                        onChange={handleFieldChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <FormInput
                      multiline
                      required
                      id="message"
                      label="Message"
                      name="message"
                      placeholder="Message"
                      rows={4}
                      value={fields.message}
                      onChange={handleFieldChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={6} sx={{ display: { sm: "grid", xs: "none" } }} />
              <Grid item sm={6}>
                <Stack
                  alignItems={{
                    xs: "center",
                    md: verticalLayout ? "center" : "flex-start",
                  }}
                  spacing={2}
                >
                  <Checkbox
                    required
                    checked={privacyAccepted}
                    darkMode={darkMode}
                    id="privacy-checkbox"
                    label={privacyText}
                    name="privacy-checkbox"
                    onChange={(): void => setPrivacyAccepted(!privacyAccepted)}
                  />
                  <SubmitButton
                    color="secondary"
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </SubmitButton>
                </Stack>
              </Grid>
            </React.Fragment>
          ) : (
            <LoadingSpinner
              container
              item
              alignItems="center"
              justifyContent="center"
              md={6}
            >
              <CircularProgress
                aria-label="contact-form-loading"
                color="primary"
              />
            </LoadingSpinner>
          )}
        </Grid>
      </StyledContainer>
    </Container>
  );
}
