import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { shouldForwardProp } from "../../utils";

type PpContainer = {
  $standardWidth: boolean;
};

export const Container = styled(Stack, { shouldForwardProp })<PpContainer>`
  ${({ $standardWidth, theme }): SerializedStyles => css`
    ${$standardWidth &&
    css`
      ${theme.cssMixins.sectionHeading};
    `}
  `}
`;

export const IconWrapper = styled("div")`
  ${({ theme }): SerializedStyles => css`
    margin-bottom: ${theme.spacing(1)};
  `}
`;
