/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from "react";
import loadable from "@loadable/component";

export * from "./MenuOpenIcon";
export * from "./MenuCloseIcon";

export function dynamicallyLoadIcon(iconId: string): React.ReactElement | null {
  if (!iconId || iconId === "") return null;
  const SVG = loadable(() => import(`./${iconId}`), {
    resolveComponent: (components) => components[iconId],
  });
  return <SVG />;
}
